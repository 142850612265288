/* eslint-disable */ export default (projectScripts) => {
  if (projectScripts) {
    projectScripts.forEach((file) => {
      switch (file) {
        case 'appointments':
          import(/* webpackChunkName: "js/legacy/appointments" */'../chunks/appointments');
          break;
        case 'contactUs':
          import(/* webpackChunkName: "js/legacy/contactUs" */'../chunks/contactUs');
          break;
        case 'globaleAnalytics':
          import(/* webpackChunkName: "js/legacy/globaleAnalytics" */'../chunks/globaleAnalytics');
          break;
        case 'hubbox.val':
          import(/* webpackChunkName: "js/legacy/hubbox.val" */'../../../../../../int_hubbox_sfra_tfg/cartridge/client/default/js/chunks/hubbox.val');
          break;
        case 'paypalCreditFinancingOptions.val':
          import(/* webpackChunkName: "js/legacy/paypalCreditFinancingOptions.val" */'../../../../../../int_paypal_sfra_tfg/cartridge/client/default/js/chunks/paypalCreditFinancingOptions.val');
          break;
        case 'paypalMFRA.val':
          import(/* webpackChunkName: "js/legacy/paypalMFRA.val" */'../../../../../../int_paypal_sfra_tfg/cartridge/client/default/js/chunks/paypalMFRA.val');
          break;
        case 'paypalUtils.val':
          import(/* webpackChunkName: "js/legacy/paypalUtils.val" */'../../../../../../int_paypal_sfra_tfg/cartridge/client/default/js/chunks/paypalUtils.val');
          break;
        case 'newsletter':
          import(/* webpackChunkName: "js/legacy/newsletter" */'../../../../../../plugin_newsletter_tfg/cartridge/client/default/js/chunks/newsletter');
          break;
        case 'wishlist':
          import(/* webpackChunkName: "js/legacy/wishlist" */'../../../../../../plugin_wishlists_tfg/cartridge/client/default/js/chunks/wishlist');
          break;
        default:
          if (process.env.NODE_ENV !== 'production') {
            console.error(`Unknown chunk "${file}".`); // eslint-disable-line no-console
          }
      }
    });
  }
}; // eslint-disable-line eol-last