export default (additionalScripts) => {
  if (additionalScripts) {
    additionalScripts.forEach((file) => {
      switch (file) {
        case 'addressBook':
          import(/* webpackChunkName: "js/legacy/addressBook" */'storefront/sfra/addressBook');
          break;
        case 'cart':
          import(/* webpackChunkName: "js/legacy/cart" */'storefront/sfra/cart');
          break;
        case 'checkout':
          import(/* webpackChunkName: "js/legacy/checkout" */'storefront/sfra/checkout');
          break;
        case 'checkoutRegistration':
          import(/* webpackChunkName: "js/legacy/checkoutRegistration" */'storefront/sfra/checkoutRegistration');
          break;
        case 'login':
          import(/* webpackChunkName: "js/legacy/login" */'storefront/sfra/login');
          break;
        case 'orderHistory':
          import(/* webpackChunkName: "js/legacy/orderHistory" */'storefront/sfra/orderHistory');
          break;
        case 'paymentInstruments':
          import(/* webpackChunkName: "js/legacy/paymentInstruments" */'storefront/sfra/paymentInstruments');
          break;
        case 'productDetail':
          import(/* webpackChunkName: "js/legacy/productDetail" */'storefront/sfra/productDetail');
          break;
        case 'productTile':
          import(/* webpackChunkName: "js/legacy/productTile" */'storefront/sfra/productTile');
          break;
        case 'profile':
          import(/* webpackChunkName: "js/legacy/profile" */'storefront/sfra/profile');
          break;
        case 'search':
          import(/* webpackChunkName: "js/legacy/search" */'storefront/sfra/search');
          break;
        case 'storeLocator':
          import(/* webpackChunkName: "js/legacy/storeLocator" */'storefront/sfra/storeLocator');
          break;
        default:
          if (process.env.NODE_ENV !== 'production') {
            console.error(`Unknown chunk "${file}".`); // eslint-disable-line no-console
          }
      }
    });
  }
}; // eslint-disable-line eol-last